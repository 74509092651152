import { Image } from "./image";
import React,{useState,useEffect} from "react";
import './Section.css';

const data = [
  { 
    id: 1,
    image :['img/TechStack/FrontEnd.png'], 
    title: 'Front End Development', 
    imageUrl: [
      'img/TechStack/Figma.png',
      'img/TechStack/Html.png',
      'img/TechStack/Css.png',
      'img/TechStack/Bootstrap.png',
      'img/TechStack/AngularJs.png',
      'img/TechStack/React.png',
      'img/TechStack/Tailwind.png',
    ],

    labels: ['Figma', 'HTML', 'CSS', 'Bootstrap', 'Angular.JS', 'React JS', 'Tailwind'],

  },

  {
     id: 2,
     image :['img/TechStack/BackEnd.png'], 
     title: 'Back End Development', 
     imageUrl:[
      'img/TechStack/JS.png',
      'img/TechStack/NodeJs.png',
      'img/TechStack/Redis.png',
      'img/TechStack/MySql.png',
      'img/TechStack/MongoDB.png',
      'img/TechStack/Larvel.png',
      'img/TechStack/Php.png',
      'img/TechStack/CodeIgniter.png',
  
     ],
     labels: ['JavaScript', 'Node.js', 'Redis', 'MySQL', 'MongoDB', 'Laravel', 'PHP', 'CodeIgniter'],

  },

  { 
    id: 3, 
    image :['img/TechStack/MobileApp.png'],
    title: 'Mobile App Development', 
    imageUrl:[
      'img/TechStack/React.png',
      'img/TechStack/Android.png',
      'img/TechStack/Ionic.png',
  ] ,
  labels: ['React Native', 'Android', 'Ionic'],

  },

  // Add more items as needed
];

function TechStack() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (itemId) => {
    setSelectedItem(itemId);
  };

  const options = ["Front End Development", "Back End Development", "Mobile App Development"];
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 2000); 

    return () => {
      clearInterval(interval);
    };
  }, []);



  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Tech Stacks</h2>
         
        </div>
        <div className="row">
          <div className="portfolio-items">

          <div className="App">
      <div className="item-list">
        {data.map((item) => (
          <div
            key={item.id}
            className={`item ${selectedItem === item.id ? 'active' : ''}`}
            onClick={() => handleItemClick(item.id)}
          >
            <img src={item.image} className="item-img"></img>
            {item.title}
          </div>
        ))}
      </div>
      <div className="item-details">
        {selectedItem !== null ? (
          <div>
            <h2>{data[selectedItem - 1].title}</h2>
            <p>{data[selectedItem - 1].content}</p>
            {data[selectedItem -1].imageUrl && (
              <div className="column card-grid">
                {data[selectedItem -1].imageUrl.map((url,index)=>(
                  <div key={index} className="col-md-4">
                    <div className="logo-card ">
                  <img  key={index} src={url} alt={`Image ${index}`}></img>
                  <div className="logo-label">{data[selectedItem - 1].labels[index]}</div>
              </div>
              </div>
                ))}
                </div>
            )}
          </div>
        ) : (

          <div>
                   <h3 style={{fontSize:"40px", color:"grey", }}> {"Click"}</h3> 

          <h2>
            <span style={{ color: "#fa5f34" ,fontSize:"27px"}}>
              {options[currentOptionIndex]}
            </span>
          </h2>
        </div>

        )}
      </div>
    </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TechStack;
