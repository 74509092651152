import React, { useState, useEffect, useRef } from "react";

export const Testimonials = (props) => {
  const [autoplay, setAutoplay] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);

  const toggleAutoplay = () => {
    setAutoplay(!autoplay);
  };

  useEffect(() => {
    if (autoplay) {
      const interval = setInterval(() => {
        // Calculate the next active index
        const nextIndex = (activeIndex + 1) % props.data.length;
        setActiveIndex(nextIndex);

        // Scroll to the next testimonial
        const container = containerRef.current;
        const testimonialWidth = container.offsetWidth / 3; // Adjust this value based on the number of testimonials in a row
        container.scrollBy({
          left: testimonialWidth, // Scroll one testimonial width
          behavior: "smooth",
        });
      }, 2000); // Adjust the interval as needed (5 seconds in this example)

      return () => {
        clearInterval(interval);
      };
    }
  }, [autoplay, activeIndex, props.data]);

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="testimonial-slider">
          <div
            ref={containerRef}
            id="testimonial-container"
            className="testimonial-container"
          >
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className={`testimonial-card ${
                      i === activeIndex ? "active" : ""
                    }`}
                  >
                    <div className="testimonial-image">
                      <img src={d.img} alt="" />
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
